import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import { Hero, Section } from "../../components/section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faChevronRight, faEnvelope, faSearch } from "@fortawesome/free-solid-svg-icons"

import slugify from "slugify"
import { useFlexSearch } from 'react-use-flexsearch'
import { useAsync } from "react-async"
import { SearchResults } from "../../components/ghost/search-results"
import SEO from "../../components/SEO/SEO"
import { faDiscord } from "@fortawesome/free-brands-svg-icons"

var FlexSearch = require("flexsearch");

const onLiveChatClick = (e) => {
  e.preventDefault();
  // window.fcWidget && console.log(window.fcWidget.isLoaded(), window.fcWidget.isInitialized(), window.fcWidget.isOpen())
  window.fcWidget && !window.fcWidget.isOpen() && window.fcWidget.open({ name: "Support" })
}

const loadIndex = async ({ indexUrl }) => {
  const res = await fetch(`${indexUrl}`)
  if (!res.ok) throw new Error(res.statusText)
  return res.text()
}

const initialIndex = new FlexSearch()

export default function Help({ data, location }) {
  const categories = data.root.categories
  enhanceArticles(categories)

  const categoryOrder = data.site.siteMetadata.help.cullai.categoryOrder
  categories.sort((a, b) => categoryOrder.indexOf(a.name) - categoryOrder.indexOf(b.name))

  const [query, setQuery] = useState()
  const [isSearchResultsShown, setIsSearchResultsShown] = useState(false)
  const { publicIndexURL, store } = data.localSearchArticles
  const { data: index, isPending: indexLoading, error: indexError } = useAsync({
    promiseFn: loadIndex,
    indexUrl: publicIndexURL
  })

  const results = useFlexSearch(query, indexLoading ? initialIndex : index, store)
  results.forEach(enhanceSearchResult)

  const onChangeQuery = (e) => {
    setQuery(e.target.value)
    setIsSearchResultsShown(true)
  }

  return (
    <Layout>
      <SEO
        title="CullAi Help Center | DopeAI"
        pathname={location.pathname}
        desc="Help Center for getting help and support for CullAi product"
      />
      <Hero nogrid className="bg-gray-200 py-20">
        <div className="flex flex-col items-center relative">
          <h1 className="text-2xl text-center">How can we help you today?</h1>
          <div className="relative w-64 md:w-96 text-lg flex items-center justify-center mt-8">
            <input onChange={onChangeQuery} className="w-full h-14 px-4 focus:ring-blue-500 focus:border-blue-500 block border-2 border-gray-300 rounded-md text-gray-900"
              placeholder="Search" >
            </input>
            <FontAwesomeIcon className="absolute right-6 text-xl text-gray-400" icon={faSearch}></FontAwesomeIcon>
          </div>
          <SearchResults
            name="help-search-results"
            results={results}
            isOpen={isSearchResultsShown}
            setIsOpen={setIsSearchResultsShown}>
          </SearchResults>
        </div>
      </Hero>
      <Section noborder gridClassName="gap-16 md:gap-10">
        {categories.map(category => (
          <div key={category.slug} className="md:col-span-6 lg:col-span-4">

            <section className="text-gray-600">
              <header className="flex items-center mb-5 pb-3 text-gray-900 border-b border-gray-200">
                <h2 className="text-xl font-bold">{category.name}</h2>
                <div className="ml-4 text-gray-400">
                  {category.totalCount} articles
                </div>
              </header>
              <ul className="topic-article-feed u-plain-list">
                {category.articles.slice(0, 7).map(article => (
                  <li key={article.slug} className="topic-article">
                    <h3 className="topic-article-title">
                      <Link className="flex items-center text-gray-600 hover:text-blue-600 py-2" to={article.url}>
                        <FontAwesomeIcon className="mr-2 text-xs" icon={faChevronRight}></FontAwesomeIcon>
                        {article.frontmatter.title}
                      </Link>
                    </h3>
                  </li>
                ))}
              </ul>
              <footer className="mt-4">
                <Link className="text-sm uppercase inline-flex items-baseline text-blue-500 hover:text-blue-600" to={`/help/cullai/${category.slug}`}>
                  <span>View All</span>
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} size="xs"></FontAwesomeIcon>
                </Link>
              </footer>
            </section>

          </div>
        ))}
      </Section>
      <Section nogrid>
        <div className="text-center">
          <h2 className="text-2xl text-gray-900 font-extrabold mb-8">Did not find what you were looking for?</h2>
          <p className="text-lg">I will be adding user guides for <b>OptiCull</b> after launch.</p>
          <p className="text-lg">In mean time please ask your query on <a href="https://discord.gg/DQBFUub7ce" target="_blank"><FontAwesomeIcon className="text-indigo-500 hover:text-indigo-400" icon={faDiscord} size="lg" fixedWidth /> Discord</a> or write us at <a href="mailto:support@dopeai.tech"><FontAwesomeIcon className="text-gray-500 hover:text-gray-400" icon={faEnvelope} fixedWidth /> support@dopeai.tech</a>.</p>
        </div>
      </Section>
    </Layout >
  )
}

export const pageQuery = graphql`
query CategoriesQuery {
  root: allMdx(
    filter: {
      frontmatter: {product: {eq: "cullai"}}, 
      fields: { source: {eq: "help"} }
    }
    sort: {fields: frontmatter___sn, order: ASC}
  ) {
    categories: group(field: frontmatter___category) {
      name: fieldValue
      totalCount
      articles: nodes {
        excerpt(pruneLength: 200)
        frontmatter {
          title
          product
          category
        }
        fields {
          source
        }
      }
    }
  }

  site {
    siteMetadata {
      help {
        cullai {
          categoryOrder
        }
      }
    }
  }

  localSearchArticles {
    store
    publicIndexURL
  }
}
`

function slug(str) {
  return slugify(str, { lower: true })
}

export function enhanceArticles(categories) {
  categories.forEach(category => {
    category.slug = slug(category.name)
    category.url = `/help/cullai/${category.slug}`
    category.articles.forEach(enhanceArticle)
  })
}

const author = {
  name: "Shailesh Pandit",
  url: "/about",
  image: "/images/author/shailesh.jpg",
  socialLinks: ["https://www.facebook.com/shailesh.pandit/", "https://twitter.com/shaileshpandit"]
}

export function enhanceArticle(article) {
  if (article.fields.source == 'help') {
    return enhanceHelpArticle(article)
  } else if (article.fields.source == 'blog') {
    return enhanceBlogArticle(article)
  } else {
    throw new Error("Article source unknown")
  }
}

export function enhanceHelpArticle(article) {
  article.author = author
  article.frontmatter.productSlug = slug(article.frontmatter.product)
  article.frontmatter.categorySlug = slug(article.frontmatter.category)
  article.slug = article.frontmatter.slug ?? slug(article.frontmatter.title)
  article.url = `/help/cullai/${article.frontmatter.categorySlug}/${article.slug}`
  article.categoryUrl = `/help/cullai/${article.frontmatter.categorySlug}`
  article.datePublished = article.frontmatter.date
}

export function enhanceBlogArticle(article) {
  article.author = author
  article.frontmatter.categorySlug = slug(article.frontmatter.category)
  article.slug = article.frontmatter.slug ?? slug(article.frontmatter.title)
  article.categoryUrl = `/blog/${article.frontmatter.categorySlug}`
  article.url = `${article.categoryUrl}/${article.slug}`
}

export function enhanceSearchResult(searchResult) {
  searchResult.categorySlug = slug(searchResult.category)
  searchResult.slug = slug(searchResult.title)
  searchResult.url = `/help/cullai/${searchResult.categorySlug}/${searchResult.slug}`
}