import { Link } from "gatsby";
import * as React from "react"

import onClickOutside from "react-onclickoutside";

function SearchResultsInternal({ name, results, isOpen, setIsOpen }) {
  SearchResultsInternal['handleClickOutside_' + name] = () => setIsOpen(false);

  return isOpen && (
    <div className="search-result absolute top-full overflow-hidden overflow-y-scroll mt-1 max-h-96 bg-white rounded shadow divide-y w-full max-w-md z-10">
      {results.map(result =>
        <div key={result.id} className="search-result-row">
          <Link className="search-result-row-link px-4 py-3 block w-full hover:bg-gray-50" to={result.url}>
            <div className="flex justify-between mb-1">
              <div className="search-result-row-title text-base text-gray-700 truncate">{result.title}</div>
              <div className="flex-shrink-0 rounded-full py-1 px-3 bg-blue-50 text-blue-400 text-xs font-normal ml-1">{result.category}</div>
            </div>
            <div className="search-result-row-excerpt text-sm text-gray-500 truncate">{result.excerpt}</div>
          </Link>
        </div>
      )}
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: ({ props }) => SearchResultsInternal['handleClickOutside_' + props.name]
};

export const SearchResults = onClickOutside(SearchResultsInternal, clickOutsideConfig);